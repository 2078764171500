.search-page-layout-v1 {
	background-color: var(--color-grey-light);
	margin-top: var(--page-margin-top);

	.alert-info {
		background-color: var(--widget-header-bg-color-gray);
		border-color: var(--widget-header-bg-color-gray);
		color: #fff;
	}

}