.static-view {
	margin-top: var(--page-margin-top);

	&.reiseschutz-v1 {
		iframe {
			width: 100%;
			height: 1000px;
			border: 1px solid #ccc;
		}
	}

	&.UrlaubBahnhof-v1 {
		.inner-banner {
			background-image: url(RESOURCE/img/urlaub-banner.jpg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			height: 490px;
			position: relative;


		}

		.single-unit {
			img {
				width: 100%;
			}

			.unit-cont-bl {
				padding: 15px 0;
			}
		}
	}
}